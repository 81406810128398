import { IconInputProps } from '../types/icons';

const Settings = ({ className }: IconInputProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 12.8799V11.1199C2.5 10.0799 3.35 9.21994 4.4 9.21994C6.21 9.21994 6.95 7.93994 6.04 6.36994C5.52 5.46994 5.83 4.29994 6.74 3.77994L8.47 2.78994C9.26 2.31994 10.28 2.59994 10.75 3.38994L10.86 3.57994C11.76 5.14994 13.24 5.14994 14.15 3.57994L14.26 3.38994C14.73 2.59994 15.75 2.31994 16.54 2.78994L18.27 3.77994C19.18 4.29994 19.49 5.46994 18.97 6.36994C18.06 7.93994 18.8 9.21994 20.61 9.21994C21.65 9.21994 22.51 10.0699 22.51 11.1199V12.8799C22.51 13.9199 21.66 14.7799 20.61 14.7799C18.8 14.7799 18.06 16.0599 18.97 17.6299C19.49 18.5399 19.18 19.6999 18.27 20.2199L16.54 21.2099C15.75 21.6799 14.73 21.3999 14.26 20.6099L14.15 20.4199C13.25 18.8499 11.77 18.8499 10.86 20.4199L10.75 20.6099C10.28 21.3999 9.26 21.6799 8.47 21.2099L6.74 20.2199C5.83 19.6999 5.52 18.5299 6.04 17.6299C6.95 16.0599 6.21 14.7799 4.4 14.7799C3.35 14.7799 2.5 13.9199 2.5 12.8799Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Settings;
