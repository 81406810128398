export const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_CRYPTO_SECRET_KEY,
  REACT_APP_SUPABASE_URL,
  REACT_APP_SUPABASE_ANON_KEY,
  REACT_APP_FRONTEND_URL,
  REACT_APP_BACKEND_URL,
  REACT_APP_FCM_API_KEY,
  REACT_APP_FCM_AUTH_DOMAIN,
  REACT_APP_FCM_PROJECT_ID,
  REACT_APP_FCM_STORAGE_BUCKET,
  REACT_APP_FCM_MESSAGING_SENDER_ID,
  REACT_APP_FCM_APP_ID,
  REACT_APP_FCM_MEASUREMENT_ID,
  REACT_APP_FCM_VAPID_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_DATE_FORMAT,
} = process.env;
