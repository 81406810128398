import { IconInputProps } from '../types/icons';

const Users = ({ className }: IconInputProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M9.66006 10.87C9.56006 10.86 9.44006 10.86 9.33006 10.87C6.95006 10.79 5.06006 8.84 5.06006 6.44C5.06006 3.99 7.04006 2 9.50006 2C11.9501 2 13.9401 3.99 13.9401 6.44C13.9301 8.84 12.0401 10.79 9.66006 10.87Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.91 4C18.85 4 20.41 5.57 20.41 7.5C20.41 9.39 18.91 10.93 17.04 11C16.96 10.99 16.87 10.99 16.78 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.65997 14.56C2.23997 16.18 2.23997 18.82 4.65997 20.43C7.40997 22.27 11.92 22.27 14.67 20.43C17.09 18.81 17.09 16.17 14.67 14.56C11.93 12.73 7.41997 12.73 4.65997 14.56Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.84 20C19.56 19.85 20.24 19.56 20.8 19.13C22.36 17.96 22.36 16.03 20.8 14.86C20.25 14.44 19.58 14.16 18.87 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Users;
